.faq-container {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 80px;
  }

  .faq-container h1{
    font-size: 35px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: center;

  }
  
  .faq {
    border: 1px solid #000000;
    margin-bottom: 20px;
    border-radius: 10px;
    
    
  }

  .faq:hover{
    transition: transform 0.3s;
    transform: scale(1.05);
  }
  
  .faq-question {
    background-color: #ffffff;
    padding: 15px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    /* border-left: 5px solid; */
  }
  
  .faq-question:hover {
    /* background-color: #cacaca; */
    /* transition: transform 0.3s;
    transform: scale(1.05); */
  }
  
  .faq-question p {
    font-weight: 500;
    margin: 0;
    font-size: 18px;
  }
  
  .faq-question span {
    position: absolute;
    top: 50%;
    right: 15px;
    
    transform: translateY(-50%);
    font-size: 24px;
    border: 1px solid;
    padding: 0 12px;
    border-radius: 25px;
  }
  
  .faq-answer {
    padding: 15px;
    border-top: 1px solid;
    border-radius: 0 0 10px 10px;
    /* border-radius: 10px; */
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    /* border-left: 5px solid; */
  }
  
  .faq-answer p {
    margin: 0;
  }
  
  .load-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    /* background-color: #007bff; */
    color: #000000;
    border: 1px solid black;
    border-radius: 45px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 45%;
  }
  
  .load-more-btn:hover {
    background-color: #0056b3;
  }
  
@media only screen and (max-width: 600px) {
    .faq-container{
        width: 95%;
        margin-bottom: 20px;
    }
    .faq p{
        width: 80%;
    }
    
    .faq-container h1{
        font-size: 28px;
        line-height: 1.8rem;
    }
    .load-more-btn{
        margin-left: 32%;
        margin-top: 0;

    }

}