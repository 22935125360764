.data-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th, table td {
    border: 1px solid #a9a9a9;
    padding: 8px;
    text-align: left;
  }
  
  table th {
    background-color: #5c95ff;
  }
  
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  table tr:hover {
    background-color: #ddd;
  }
  
  @media screen and (max-width: 600px) {
    table {
      overflow-x: auto;
    }
  }
  