/* Landing.css */
*{
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;

}

@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sriracha&display=swap');

.landing-container {
    position: relative;
    height: 700px;
    /* border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%; */
    background-image: url('./Landingbg.png');
    background-size:cover ;
    /* background-image: linear-gradient(to right, #ffffff, #000000, #c6edff); */
  }


  
  .LandingText{
    width: 60%;
    margin: auto;  
}

 

  .LandingBtn1{
    margin-left: 20%;
    width: 60%;
    padding: 10px;
    font-weight: 700;
    color: white;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 100px;
    /* margin-left: 8%; */
    margin-top: 390px;
    z-index: 1;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    
    /* background: linear-gradient(135deg, #ff558e, #fdd5b1); Adjust gradient colors */

  }

  .LandingBtn1:hover{
    transition: transform 0.3s;
    transform: scale(1.05);
  }


  .LandingText h1{
    padding-top: 10%;
    font-size: 55px;
    font-weight: 450;
    margin: 0;
    text-align: center;
    line-height: 64px;
    color: rgb(255, 255, 255);
    position: relative;

    /* z-index: 1; */
    



  }




  .Buildology{
    padding-top: 5%;
        width: 40%;
        height: auto;
        margin-left: 40%;
  }

  .BLogo{
    width: 40%;
        height: auto;
  }


  .gradient-shape {
    position: absolute;
    width: 200px; /* Adjust shape size */
    height: 200px; /* Adjust shape size */
    border-radius: 50%; /* To make it a circle */
    background: linear-gradient(135deg, #ff558e, #fdd5b1); /* Adjust gradient colors */
    clip-path: polygon(50%, 50%);
  }
  
  /* Positioning gradient shapes */
  .gradient-shape-1 {
    top: -50px;
    left: -50px;
  }
  
  .gradient-shape-2 {
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .gradient-shape-3 {
    bottom: -50px;
    right: -50px;
  }

.LandingImg{
    /* margin-top: 200px; */
    width: 100%;
}

.LandingImgCont{
    height: 500px;
    width: 60%;
    margin: auto;
}

.Middle-Left{
    width: 15%;
    margin: auto;
    display: none;
}

.MiddleRight{
    width: 15%;
    margin: auto;
    display: none;
}

.LandingMiddleCont{
    width: 100%;
    margin: auto;
    display: flex;
}

.Middle-Left p{
    width:75%;
    font-weight: 600;
    margin-left: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.Landing-Vid{
    width: 75%;
    height: 200px;
    border: 1px solid;
    border-radius: 10px;
    margin-left: auto;
}

.ClientsNo{
    border: 1px solid white;
    border-radius:10px ;
    padding: 15px;
    width: 50%;
    margin-bottom: 10px;
}

.ClientsNo h1{
    font-size: 45px;
    color: white;

}

.ClientsNo p{
    font-size: 30px;
    color: white;
    
}

.LandingBtnCont{
    width: 75%;
    margin-left: auto;
}

.LandingBtn{
    padding: 10px 40px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 45px;
    cursor: pointer;
    outline: none;
    margin-right: 50px;
    width: 100%;
    font-size: 14px;
}

/* Media Query */

@media only screen and (max-width: 700px) {
    .LandingText h1{
        font-size: 35px;
        padding-top: 80px;
        text-align: left;
        
    }

    .landing-container {
        position: relative;
        height: 600px;
        width: 100%;
        background-size: cover;
        background-position: center        /* border-bottom-left-radius: 40%;
        border-bottom-right-radius: 40%; */
        /* background-image: linear-gradient(to right, #ffffff, #000000, #c6edff); */
      }

    .LandingText{
        width: 94%;
    }

    .LandingText h1{
        font-size: 30px;
        padding-top: 18%;
        line-height: 2rem;
        text-align: center;
    }


    .Middle-Left{
        width: 90%;
        margin: auto;
    }
    .MiddleRight{
        width: 10%;
    }

    .LandingBtn{
        padding: 5px;
    }

    .LandingBtn1{
        margin-top: 95%;

    }

    
  }
  