.painpoints-grid {
    width: 60%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
    gap: 20px;
  }
  
  .painpoint-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Add shadow for depth */
  }
  
  .painpoint-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0; /* Rounded corners only at the top */
  }
  
  .painpoint-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* Gradient effect */
  }
  
  .painpoint-card h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    z-index: 1; /* Ensure text is above gradient effect */
    margin-bottom: 0; /* Reset margin */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(41, 41, 41, 0.5));

  }
  
  .painpoint-card p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for description */
    margin: 0; /* Reset margin */
    z-index: 1; /* Ensure text is above gradient effect */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition for hover effect */
  }
  

  .painpoint-card:hover p {
    opacity: 1; /* Show description on hover */
  }
  

  @media only screen and (max-width: 600px) {
        
        .painpoints-grid{
          width: 90%;
        }

  }