.NewsLetter{
    width: 70%;
    border-radius: 20px;
    height: 150px;
    border: 2px solid black;
    margin: auto;
    display: flex;
    background-color: #a4a7ff;

  }

  .NewsLetter-Text h2{
    font-size: 30px;
    font-weight: 500;
    line-height: 2.5rem;
    width: 75%;
    color: #000000;
    margin-left: 35px;
    padding-top: 30px;
  }

  .NewsLetter-btn{
    width: 35%;
    margin: auto;
  }

  .News-contact{
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
    width: 90%;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 600;
    color: white;
  }


  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the popup is above other content */
  }
  
  /* Popup Content (Modern and Stylish Touches) */
  .popup-content {
    width: fit-content; /* Adjust width as needed */
    height: 450px;
    background-color: #fff; /* White background */
    padding: 30px; /* Adjust padding for spacing */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Arrange children vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
  
    /* Optional: Subtle border for more definition */
    border: 1px solid #ddd;
  }
  
  
  .popup-close {
    position: absolute;
    top: 15px; /* Adjust position as needed */
    right: 15px; /* Adjust position as needed */
    font-size: 24px; /* Larger close icon */
    cursor: pointer;
    color: #333; /* Darker text color */
    transition: color 0.3s ease; /* Smoother color transition */
    background-color: transparent; /* Transparent background for hover effect */
  
    /* Optional: Add hover effect to highlight */
    &:hover {
      color: #000; /* Change color on hover */
    }
  }
  
  /* Animation for Popup Entrance (Optional) */
  .popup-content {
    animation: popup-show 0.3s ease-in-out forwards;
  }
  
  @keyframes popup-show {
    from {
      opacity: 0;
      transform: scale(0.9); /* Start slightly shrunk */
    }
    to {
      opacity: 1;
      transform: scale(1); /* Animate to full size */
    }
  }
  

  @media only screen and (max-width: 600px) {
      .NewsLetter{
        width: 95%;
        height: fit-content;
        padding: 10px;
      }

      .NewsLetter-Text{
        width: 75%;
      }

      .NewsLetter-Text h2{
          font-size: 16px;
          margin: 0 0 0 0;
          padding-top: 0;
          line-height: 1.2rem;
      }

      .NewsLetter-btn{
        width: 35%;
      }

      .News-contact{
        width: 100%;
        font-size: 12px;
        padding: 10px;
      }

  
  }