html {
    scroll-behavior: smooth;
  }

  .HomePage-container{
    margin-top: 50px;
  }

.AboutUsHeader{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    padding-top: 45px;
    height: 150px;
}

.AbtUsHeaderLine-Left{
    margin-top: 20px;
    margin-left: auto;
    width: 35%;
    /* margin: auto; */
    height: 60px;
    border-top: 2px solid;
    border-left: 2px solid;
    border-top-left-radius: 10px;
}


.AbtUsHeader-Middle{
    width: 20%;
    height: fit-content;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
}

.AbtUsHeaderLine-Right{
    margin-top: 20px;
    margin-right: auto;
    width: 35%;
    /* margin: auto; */
    height: 60px;
    border-top: 2px solid;
    border-right: 2px solid;
    border-top-right-radius: 10px;
}

.AboutUsExt{
    width: 65%;
    margin: auto;
}

.MissionCont{
    display: flex;
    gap: 50px;
    margin-bottom: 100px;
}


.MissionContImg{
    width: 50%;
    height: 450px;
}

.MissionImg{
    width: 350px;
    height: 450px;
    object-fit: cover;
    border-radius: 50px;

}

.VissionText{
    text-align: left;
    width: 80%;
}

.VissionText h1{
    padding-top: 0px;
    font-size: 45px;
    font-weight: 500;
    width: 350px;
    padding-bottom: 30px;
}

.VissionText p{
    font-size: 20px;
    font-weight: 500;
}

.AboutUsBottom{
    width: 80%;
    height: 60px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: auto;
}

.Ouroffer{
    display: none;
    margin-top: 60px;
    width: 100%;
    height: 400px;
    /* background-color: #FFEAEA ; */
}



.Features{
    /* background-color: white; */
    width: 80%;
    margin: auto;
    /* border: 2px solid; */
    justify-content: center;
    font-family:'Montserrat', sans-serif;

  }

  .Features h2{
    font-family:'Montserrat', sans-serif;
    padding-bottom: 10px;
    padding-top: 20px;

  }

  .Features-banner h1{
    font-size: 45px;
    font-weight: 500;
  }

  .Features-banner{
    text-align: center;
  }

  
  

  .Fcard-container{
    display: flex;
    width: 100%;
    justify-content: space-around;
    min-height: 150px;
    margin-top: 40px;
  }

  .Fcard{
    width: 30%;
    /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(207, 194, 255)); */
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 6px;
    text-align: left;
    height: fit-content;
    margin: 10px;
    height: fit-content;
    padding-bottom: 20px;
    font-family:'Montserrat', sans-serif;
    border-left: 6px solid darkblue;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    /* border-bottom: 6px solid rgb(100, 100, 255); */
  }


.Fcard h1{

    font-weight: 800;
    font-size: 35px;
    background: -webkit-linear-gradient(#eee, #5347ff, #47fff0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Reasons-container{
    background-color: #FFEAEA;
    height: 530px;
    margin-bottom: 70px;
    margin-top: 50px;
}

.ReasonsText{
    padding-top: 20px;
    padding-bottom: 20px;

}

.ReasonsText h1{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
   }

   .Num h2{

    font-size: 25px;
    font-weight: 600;

   }

.ReasonsImgCont{
    width: 300px;
    height: 400px;
}

.ReasonsImg{
    width: 300px;
    height: 400px;
    object-fit:cover;
    border-radius: 140px;

}

.ReasonsMiddleCont{
    display: flex;
    width: 90%;
    margin: auto;
}

.RMiddle-Left{
width: 25%;
margin: auto;
}

.RMiddleRight{
    width: 25%;
    margin: auto;
}

.RCard{
    padding-left: 15px;
    text-align: left;
    border-left: 5px solid darkblue;
    margin-bottom: 15px;
}
.RCard p{
    font-weight: 600;
}

/* Media Query */

@media only screen and (max-width: 600px) {
   .AboutUsHeader{
    width: 95%;
    height: 100px;
   }
   .AbtUsHeaderLine-Left{
    width: 25%;
    height: 20px;
    
   }
   .AbtUsHeaderLine-Right{
    width: 25%;
    height: 20px;
   }

   .AbtUsHeader-Middle{
    width: 50%;
   }

   .AbtUsHeader-Middle h1{
    font-size: 25px;

   }

   .AboutUsExt{
    width: 90%;
   }

   .MissionCont{
    flex-direction: column;
    width: 100%;
    margin: 0 0 0 0 ;
   }
   .MissionContImg{
    width: 100%;
    order: -1;
   }
   .VissionText{
    width: 100%;
   }

   .VissionText h1{
    width: 100%;
    font-size: 35px;
    font-weight: 600;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
   }

   .VissionText p{
    padding-bottom: 20px;
    font-size: 18px;
    text-align: center;

   }
   .AboutUsBottom{
    width: 95%;
    height: 20px;
    margin-bottom: 0;
   }

   .OurOfferCard{
    width: 100%;
    height: fit-content;
   }
   .Features-banner h1{
    font-size: 35px;
    font-weight: 600;
   }

   .Fcard-container{
    height: fit-content;
    flex-direction: column;
   }

   .Fcard{
    width: 100%;
   }
   .Features-container{
    width: 100%;
    
   }
   .Reasons-container{
    margin-top: 50px;
   }

   .ReasonsText h1{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
   }
   .ReasonsMiddleCont{
    flex-direction: column;
   }

   .RCard{
    width: 100%;
   }

   .RMiddle-Left{
    width: 100%;
   }
   .RMiddleRight{
    width: 100%;
   }

   .Num h2{

    font-size: 25px;
    font-weight: 500;

   }
   .ReasonsImgCont{
    display: none;
   }

   .Reasons-container{
    height: fit-content;
    padding-bottom: 20px;
    margin-bottom: 25px;
   }

   .footer-container{
    height: fit-content;
   }

   


  }
  