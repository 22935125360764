.ServiceLanding{
    background-image: linear-gradient(to left, rgba(255,0,0,0), rgb(248, 255, 144,0.5)),url('../images/Construction.png');
    background-size:cover ;
    width: 100%;
    height: 750px;

    /* background-color: aqua; */
}

.ServiceText{
    width: 60%;
    margin-right: auto;
    padding-top: 250px;
    padding-left: 150px;
    text-align: left;
    /* background: -webkit-linear-gradient(left, rgb(255, 255, 255) , blue);
    background: -o-linear-gradient(right, rgb(255, 255, 255), blue);
    background: -moz-linear-gradient(right, rgb(255, 255, 255), blue);
    background: linear-gradient(to right, rgb(255, 255, 255) , blue); 
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent; */
}

.ServiceText h1{
    font-size: 45px;
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.ServiceBtn{
    width: 100%;
    /* margin-left: 70px; */
    
}

.ServContBtn{
    width: 60%;
    padding: 10px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    border: 2px solid rgb(0, 0, 0);
    border-radius: 100px;
    /* margin-left: 8%; */
    margin-top: 35px;
    z-index: 1;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    transition: transform 0.3s
}

.ServContBtn:hover{
    transform: scale(1.05); 
    background-color: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.ServicesContList{
    padding-top: 50px;
    /* display: flex; */
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
}

.OurServicesImgCont{
    width: 100%;
    height: 600px;
}

.OurServicesListImg{
    width: 100%;
    height: 600px;
    object-fit: cover;
}
.servicesIcon{
    width: 10%;
    margin: auto;

}

.iconimg{
    width: 100%;
    object-fit: cover;

}

.ServiceList1{
    display: flex;
    border: 1px solid;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 10px;
    /* height: 100px; */
}

.ServicesContList h1{
    font-size: 35px;
    text-align: center;
    font-weight: 600;
}

.ServiceListText{
    width: 80%;
    margin: auto;
    text-align: left;
}

.ServicesListCont{
    width: 60%;
}

.SReasons-container{
    background-color: #D0FEDA;
    margin-bottom: 50px;
    margin-top: 50ox;
    height: 550px;
    display: none;
}

.SRCard{
    padding-left: 15px;
    text-align: left;
    border-left: 5px solid rgb(255, 221, 0);
    margin-bottom: 15px;
}

.PainpointsCont{
    padding-top: 50px;
    margin: auto;
    background-color: #e3eeff;
    margin-bottom: 50px;
    margin-top: 20ox;
    height: 550px;
}
.PCont{
    width: 80%;
    margin: auto;
    display: flex;
    gap: 10px;

}
.PHeader{
    width: 40%;
}

.PainpointsCont h1{
    font-size: 35px;
    font-weight: 500;
    /* height: 100%; */
    text-align: left;
    padding-top: 20%;
    line-height: 2.5rem;
    padding-bottom: 20px;
}


.why{
    font-size: 50px;
    font-weight: 600;
    
}

@media only screen and (max-width: 768px) {
    .ServiceLanding{
        width: 100%;
        height: 600px;
        position: relative;
        height: 600px;
        width: 100%;
        background-size: cover;
        background-position: 60% 40%;

    }

    .ServiceText{
        padding: 0;
        margin: 0;
        width: 100%;
    }
    .ServiceText h1{
        padding-top: 45%;
        padding-left: 10px;
        width: 100%;
        font-size: 28px;
        line-height: 2rem;
        font-weight: 500;
    }

    .ServiceBtn{
        width: 90%;
        margin: auto;

    }

    .ServContBtn{
        width: 100%;
    }

    .PainpointsCont{
        height: fit-content;
        padding-bottom: 25px;
        margin-bottom: 20px;
        padding-top: 0;
    }

    .PCont{
        width: 100%;
        flex-direction: column;
    }

    .PCont h1{
        padding-top: 10px;

    }

    .PHeader{
        padding: 20px;
        width: 100%;
    }
    .ServicesContList{
        margin-bottom: 0;
        padding: 0;
        padding-top: 25px;
    }

}
