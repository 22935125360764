/* Footer.css */
.footer-container {
    color: #ffffff;
    padding-top: 50px 0;
    text-align: center;
    font-family:'Montserrat', sans-serif;

  }

  
  
  .footer-content {
    margin-top: 70px;

    background-color: #000000;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
    text-align: center;
    color: #ffffff;
  }

  .Divider{
    width: 50%;
    height: 0.5px;
    margin-bottom:10px;
    background-color: #ffffff;
  }
  
  

  .footer-section h3 {
    font-size: 18px;
    /* margin-bottom: 10px; */
  }
  
  ul.social-icons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  ul.social-icons li {
    margin: 4px 5px;
    font-size: 35px;
  }
  
  ul.social-icons a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  ul.social-icons a:hover {
    color: #6f64ff;
  }
  
  .company-logo-container{
    margin: auto;
    width: 250px;
    height: 250px;
    /* border: 1px solid black; */
  }
  .company-logo {
    width: 250px; /* Adjust the logo size as needed */
    height: 250px;
    border-radius: 4px;
    object-fit: cover;
  }
  
  



.quick-links {
  flex: 1; /* Expand to take available space */
  padding: 20px; /* Add padding for spacing */
  color: #fff; /* Text color */
}

.quick-links h4 {
  font-weight: 500;
  text-align: center;
  font-size: 20px; /* Heading font size */
  margin-bottom: 10px; /* Spacing below the heading */
}

.quick-links ul {
  /* font-weight: 500; */
  text-align: center;
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0;
}

.quick-links {
  list-style: none;
  display: flex;
  margin: 8px; /* Spacing between each link */
}

.quick-links a {
  margin: 30px;
  text-decoration: none; /* Remove underline from links */
  color: #ffffff; /* Link text color */
}

.quick-links a:hover {
    color: #93c0ff;
}

.Privacy{
  background-color: #fefeff;
  height: 25px;
  color: rgb(0, 0, 0);
  padding-top: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 8px;
}

.Privacy a{
  text-decoration: none;
  color: rgb(0, 0, 0);


}



@media only screen and (max-width: 600px) {
  .footer-content {
    display: list-item;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 25px 0 0 0;
    padding: 0;
    width: 100%;
  }  
  .footer-container {
    color: #fff;
    text-align: center;
    font-family:'Montserrat', sans-serif;
    margin: 20px 0 0;
    padding: 0;
    width: 100%;
  }

  .Privacy h5{
    font-size: 10px;
    font-weight: 500;
    
  }
  .footer-section{
    width: 90%;
  }
  .footer-section a{
    margin-left:15px ;
  }

  .Divider{
    width: 95%;
  }

  .footer-section p{
    font-size: 16px;
  }

}



.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-section {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.company-logo-container {
  margin-bottom: 10px;
}

.tagline {
  margin-bottom: 10px;
  
}

.quick-links {
  list-style-type: none;
  padding: 0;
}

.quick-links li {
  margin-bottom: 5px;
}

.social-icons {
  list-style-type: none;
  padding: 0;
}

.social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.Privacy {
  text-align: center;
}


.FIconsCont{
  display: flex;
  margin: auto;
  width: fit-content;
}

.FIcons{
  border: 1px solid white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
  line-height: 1,5rem;
  margin-top: 2px;

}

.FooterIcons{
  margin-top: 80px;
  padding-bottom: 30px;
}