/* Responsive grid for cards */
.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Adjust card width as needed */
    gap: 20px; /* Space between cards */
    margin: 40px 0; /* Center the grid */
  }
  
  /* Individual card styles */
  .service-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent image overflow */
    text-align: center;
    transition: transform 0.3s ease-in-out; /* Add hover effect */
  }
  
  .service-card:hover {
    transform: scale(1.05); /* Slightly enlarge card on hover */
  }
  
  /* Image styling */
  .service-card img {
    width: 100%;
    height: 200px; /* Adjust image height as needed */
    object-fit: cover; /* Maintain aspect ratio */
  }
  
  /* Heading and description styling */
  .service-card h3 {
    font-size: 1.2rem;
    margin-top: 15px;
    color: #000000;
    font-weight: 600;
  }
  
  .service-card p {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  

  @media only screen and (max-width: 768px) {
    .services-grid{
      grid-template-columns: repeat(auto-fit, minmax(95%, 1fr)); /* Adjust card width as needed */

    }

  }