.FaqPageMain{
    width: 80%;
    margin: auto;
}

.FaqLanding{
    display: flex;
    width: 100%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 60px;
}

.FaqLandingtext{
    padding-top: 10%;
    width: 50%;
}

.FaqLandingtext h1{
    font-weight: bold;
    font-size: 35px;
}

.FaqLandingtext p{
    font-size: 25px;
    font-weight: 500;
}

.FaqLandingtext h4{
    font-weight: 500;
}

.mailtext{
    font-weight: 600;
    color: rgb(0, 42, 255);
}
.MailtBtn{
    border: 2px solid black;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    margin-top: 10px;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 208, 0);
}

.Faqimg{
    width: 50%;
}
.Faqimg img{
    margin-left: auto;
}

@media only screen and (max-width: 600px) {
    .FaqPageMain{
        width: 97%;
    }
    
    .FaqLanding{
        flex-direction: column;
        width: 100%;
    }

    .Faqimg{
        width: 70%;
        margin: auto;
    }

    .FaqLandingtext{
        width: 96%;
        margin: auto;
        text-align: center;

    }

    .FaqLandingtext h1{
        font-size: 30px;
    }
    .FaqLandingtext p{
        font-size: 16px;
        font-weight: 800;
    }
}