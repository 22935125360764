/* ContactForm.css */

.contact-form-container {
    width: fit-content;
    margin: 0 auto;
    height: fit-content;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .dropdown{
    padding: 1px;
  }
  
  .DropdownBtn{
    background-color: #2b6eff;
    width: 100%;
    color: white;
    font-weight: 600;

  }
  .form-group {
    width: 100%;
    margin-bottom: 5px;
    margin: auto;
  }

  
  
  label {
    font-weight: 500;
    color: black;
    text-align: left;
    margin-bottom: 5px;
    display: block; /* Display labels as blocks */
  }
  
  input[type="text"],
  input[type="tel"],
  select {
    width: 100%; /* Set the width to 100% */
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #ccc;
    outline: none;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }

  .select-box {
    border-radius: 50px;
    margin-top: 5px;
    position: relative;
    background-color: #fff;
    height: fit-content;
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); */
  }
  .dropdown-menu {
    border-radius: 10px;
    border: 1px solid #ccc;
    position: absolute;
    background-color: #fff;
    top: calc(100% + 5px); /* Position the dropdown below the button */
    left: 0;
    z-index: 999; /* Ensure the dropdown appears over other elements */
    max-height: 200px; /* Set maximum height for the dropdown menu */
    overflow-y: auto;
    width: 100%; /* Enable vertical scrolling */
  }
  .options {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    margin-bottom: 5px;
    display: flex;
    padding-left: 10px;
  }

  .option:hover{
    background-color: #2b6eff;
    color: #fff;
  }
  
  .option input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .option label {
    cursor: pointer;
  }
  
  
  button[type="submit"] {
    padding: 10px 0;
    border: none;
    border-radius: 50px;
    background: linear-gradient(to right, #41c0ff, #2b6eff);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
  }
  
  button[type="submit"]:hover {
    background: linear-gradient(to right, #2ba7ff, #4b41ff);
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    width: 300px;
    height: fit-content;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    position: relative;
    color: black;
  }
  
  .popup-content h2 {
    margin-bottom: 10px;
  }
  
  .popup-content p {
    margin-bottom: 5px;
  }
  
  .success-message button {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: rgb(81, 81, 255);
  }

  .option-header{
    font-weight: 800;
    color: rgb(143, 143, 143);
    padding-left: 5px;
  }
  