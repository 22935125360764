.requirement-form-container {
    width: 500px;
    margin: auto;
    border: 2px solid #bababa;
    /* padding: 50px; */
    margin-top: 150px;
    margin-bottom: 50px;
    border-radius: 10px;
  }

  .ReqFormCont{
    width: 90%;
    margin: auto;
  }
  .requirement-form-container h2{
    text-align: left;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type='text'],
  input[type='tel'],
  input[type='number'],
  select {
    width: 100%;
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 25px;
    
  }
  
  .requirement-form-container button {
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 50%;
    margin-right: auto;
    margin-bottom: 20px;
    background-color: rgb(88, 88, 255);
  }
  
 /* RequirementForm.css */

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    border-radius: 5px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .tickImg{
    width: 200px;
    height: 200px;
    object-fit: cover;
  }