
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    z-index: 1000;
    /* border-bottom: 1px solid; */
    padding: 5px 10px;
    
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .navbar-logo {
    width: 250px;
    height: 62px;
    margin-left: 8%;
    /* background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
    border-radius: 1px;
    backdrop-filter: blur(10px); */

  }


  .LogoImg{
    width: 250px;
    height: 62px;
    object-fit: cover;

  }

  .navbar-links{
    width: fit-content;
    margin: auto;
  }

  .navbar-links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    transition: left 0.3s ease; /* Add transition for smooth animation */

  }
  
  .navbar-links ul li {
    margin-right: 20px;
  }
  
  .navbar-links ul li a {
    text-decoration: none;
    color: #000000;
    font-weight: 600;
  }

  .navbar-links ul li a :hover {
    color: #b39800;
  }

  .navbar-links ul li a.active {
    color: #0056b3;
  }
  
  .navbar-contact{
    margin-right: 2%;
  }
  .navbar-contact button {
    padding: 10px 40px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 45px;
    cursor: pointer;
    outline: none;
    margin-right: 15px;
  }
  
  .navbar-contact button:hover {
    background-color: #b39800;
  }
  
  
  
  .Navbar-popup {
    position: fixed; /* Make the popup fixed to cover the entire viewport */
    top: 0; /* Start at the top of the viewport */
    left: 0; /* Start at the left of the viewport */
    width: 100%; /* Take up the full width of the viewport */
    height: 100vh; /* Take up the full height of the viewport */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    display: flex; /* Enable flexbox layout for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .Navbar-popup-content {
    width: fit-content; /* Adjust width as needed */
    height: fit-content; /* Adjust height as needed */
    background-color: #fff; /* White background for popup content */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Box shadow for popup */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Arrange children vertically */
    justify-content: center; /* Center vertically */
    align-items: center;  
    position: relative;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #333; /* Color for close icon */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .popup-close:hover {
    color: #000; /* Change color on hover */
  }

  .navbar-toggle {
    display: none; /* Hide toggle button by default */
  }

  
  
  @media only screen and (max-width: 768px) {
    .navbar-links ul {
      display: none; /* Hide navbar links by default on small screens */
      flex-direction: column; /* Change direction to stack links vertically */
      position: absolute;
      background-color: #ffffff;
      /* backdrop-filter: blur(10px); */
      top: 70px;
      left: 0;
      width: 100%;
      padding: 10px;
      z-index: 1000;
      transition: all 300ms ease-in-out;
      z-index: 1;
      /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
      text-align: right;

    }

  
    .navbar-links.show ul {
      display: flex;
      margin-right: 0;
      flex-direction: column; /* Show navbar links when sidebar is open */
    }
  
    .navbar-toggle {
      display: block; 
      cursor: pointer;
      margin-right: 12px;
    }
  
    .icon-bar {
      width: 20px;
      height: 2px;
      background-color: #000000;
      margin: 4px 0;
      transition: 0.4s;
    }

    .navbar-contact{
      display: none;
    }
  
    .sidebar-open .icon-bar:nth-child(1) {
      transform: rotate(-45deg) translate(-3px, 6px);
    }
  
    .sidebar-open .icon-bar:nth-child(2) {
      opacity: 0;
    }
  
    .sidebar-open .icon-bar:nth-child(3) {
      transform: rotate(45deg) translate(-3px, -6px);
    }


    .navbar-logo{
      margin-left: 2%;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    .navbar{
      width: 100%;
      padding: 0;
      
    }

    .navbar-contact{
      width: fit-content;
      margin-right: 5px;
    }
    .navbar-contact button {

      width: 100px;
      font-size: 12px;
      padding: 0;
      margin-right: 0;
      

    }

    
  }



  